<template>
  <div
    ref="readMoreRef"
    class="overflow-y-hidden transition-max-height duration-500"
    :class="{ '!max-h-[5000px]': state.isOpen }"
  >
    <div
      ref="readMoreInnerRef"
      class="overflow-y-hidden"
      :style="
        !state.isOpen &&
        state.linesToShow !== -1 &&
        `max-height: ${state.linesToShow}lh;`
      "
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="html" class="space-y-lh" v-bind="$attrs" v-html="html"></div>
      <div
        v-else
        class="space-y-lh"
        :class="{ 'md:columns-2 md:gap-10': hasTwoColumns }"
        v-bind="$attrs"
      >
        <slot></slot>
      </div>
    </div>
    <button
      type="button"
      class="mt-[1em] items-center gap-vw-1.5-to-2"
      :class="{
        '!hidden': state.isOpen,
        'inline-flex': !state.isOpen,
        hidden: state.linesToShow === -1,
      }"
      @click="open"
    >
      <b>Mehr lesen</b>
      <IconComponent class="size-vw-3-to-4 flex-none" icon="circle-plus" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useThrottleFn } from '@vueuse/core'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    linesToShowOnMobile?: number
    linesToShowOnDesktop?: number
    html?: string | null
    tolerance?: number
    hasTwoColumns?: boolean
  }>(),
  {
    linesToShowOnMobile: 4,
    linesToShowOnDesktop: 15,
    html: null,
    tolerance: 3,
    hasTwoColumns: false,
  }
)

const state = reactive({
  isOpen: false,
  linesToShow: 4,
})

const readMoreRef = ref()
const readMoreInnerRef = ref()

onMounted(() => {
  init()
  window.addEventListener('resize', onResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

function init() {
  state.linesToShow = window.matchMedia('(min-width: 768px)').matches
    ? props.linesToShowOnDesktop
    : props.linesToShowOnMobile
  nextTick(() => {
    const possibleHeight = Math.floor(
      Number(getComputedStyle(readMoreRef.value).lineHeight.replace('px', '')) *
        (state.linesToShow + props.tolerance)
    )
    const actualHeight = readMoreInnerRef.value.scrollHeight

    if (actualHeight <= possibleHeight) {
      state.linesToShow = -1
      readMoreRef.value.style.maxHeight = null
    } else {
      readMoreRef.value.style.maxHeight = `${readMoreRef.value.scrollHeight}px`
    }
  })
}

function open() {
  state.isOpen = true
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 750)
}

const onResize = useThrottleFn(() => {
  init()
  // state.isOpen = false
}, 100)
</script>
